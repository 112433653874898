import React from "react";

import styles from "./styles.module.scss";

interface ActiveCellProps {
  activeCell: {
    col: number;
    row: number;
  };
  getColumnWidth: (index: number) => number;
  columnLeftOffsets: number[];
  getRowHeight: () => number;
}

export const ActiveCell = (props: ActiveCellProps) => {
  const { activeCell, getColumnWidth, columnLeftOffsets, getRowHeight } = props;
  const { col: columnIndex, row: rowIndex } = activeCell;
  const width = getColumnWidth(columnIndex);
  const left = columnLeftOffsets[columnIndex];
  const rowHeight = getRowHeight();
  const style = {
    width,
    height: rowHeight,
    left,
    top: rowIndex * rowHeight,
  };
  return (
    <div
      key="active"
      data-role="active"
      data-testid="active-cell"
      className={styles.active}
      style={style}
    />
  );
};
