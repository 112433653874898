import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";

import { useCurrentAccount } from "components/Account/useCurrentAccount";
import { useMenu } from "components/ActionMenu/useMenu";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_DELETE_GROUP,
  ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
  ROLES_WIZARD_SET_REVOKE_PRIVILEGES,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";

import styles from "./styles.module.scss";

type Props = {
  index: number;
  resource: string;
};

const privileges = [
  "CONNECT",
  "CREATE DATABASE",
  "CREATE ENGINE",
  "CREATE LOCATION",
  "CREATE ROLE",
  "MANAGE GRANTS",
  "METER USAGE",
  "MODIFY ACCOUNT",
  "MODIFY ANY DATABASE",
  "MODIFY ANY LOCATION",
  "MONITOR ANY USAGE",
  "MODIFY ANY ROLE",
  "OPERATE ANY ENGINE",
  "USE ANY DATABASE",
  "USE ANY ENGINE",
];

export const AccountGroup = (props: Props) => {
  const { index, resource } = props;
  const { t } = useTranslation();
  const menu = useMenu();
  const { getAccount } = useCurrentAccount();
  const currentAccount = getAccount();

  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const groupState =
    privilegesState[resource as keyof typeof privilegesState][index];
  const { toAssign, toRevoke } = groupState;

  const items = [
    [
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
          });
        },
        testId: "roles-wizard-add-account-group",
      },
    ],
    ...(privilegesState.account.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: () => {
                privilegesDispatch({
                  type: ROLES_WIZARD_DELETE_GROUP,
                  resource,
                  index,
                });
              },
              testId: "roles-wizard-delete-account-group",
            },
          ],
        ]
      : []),
  ];

  const onGrantPrivileges = (items: string[]) => {
    privilegesDispatch({
      type: ROLES_WIZARD_SET_ASSIGN_PRIVILEGES,
      resource,
      privileges: items,
      index,
    });
  };

  const onRevokePrivileges = (items: string[]) => {
    privilegesDispatch({
      type: ROLES_WIZARD_SET_REVOKE_PRIVILEGES,
      resource,
      privileges: items,
      index,
    });
  };

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase()),
    };
  });

  return (
    <div className={styles.group}>
      <div className={styles.group__account}>{currentAccount.accountName}</div>
      <GrantPrivilegesSelector
        onChange={onGrantPrivileges}
        privileges={privilegesOptions}
        selectedPrivileges={toAssign}
      />

      <RevokePrivilegeSelector
        onChange={onRevokePrivileges}
        privileges={privilegesOptions}
        selectedPrivileges={toRevoke}
      />
      <Menu
        items={items}
        menu={menu}
        alwaysVisible
      />
    </div>
  );
};
