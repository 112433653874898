import { useTranslation } from "react-i18next";

import { useViewNames } from "services/databases/useViewNames";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { WithSearch } from "components/OutlinedSelect/OutlinedSelect";

import { ROLES_WIZARD_SET_RESOURCE_OBJECTS } from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { SelectItems } from "./SelectItems";

type Props = {
  catalogName: string;
  index: number;
  resource: string;
};

export const ViewsItems = (props: Props) => {
  const { index, catalogName, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const views = useViewNames({ database: catalogName });
  const { t } = useTranslation();

  const groupState = privilegesState.view[index];
  const { objects } = groupState;

  const selectedViews = objects
    .filter(object => object.catalogName === catalogName)
    .map(object => object.name);

  const items = views.map(view => {
    return (
      <ContextMenuItem
        key={view.tableName}
        value={view.tableName}
        text={view.tableName}
        direction="left"
        checked={selectedViews.includes(view.tableName)}
        onClick={() => {
          const selectedObjects = selectedViews.includes(view.tableName)
            ? objects.filter(object => object.name !== view.tableName)
            : [...objects, { name: view.tableName, catalogName }];
          privilegesDispatch({
            type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
            resource,
            index,
            database: catalogName,
            objects: selectedObjects,
          });
        }}
      />
    );
  });

  return (
    <WithSearch
      items={items}
      searchOptions={{
        searchPlaceholder: t("roles_wizard.privileges.search_view"),
        noResultsText: t("roles_wizard.privileges.no_view_found"),
      }}
    >
      <SelectItems />
    </WithSearch>
  );
};
