import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TextArea from "components/TextArea/TextArea";
import { TextInput } from "components/TextInput/TextInput";
import { Step } from "components/Wizard/Step/Step";

import { NameStepType } from "../types";
import { Fields, schema } from "./schema";

import styles from "./styles.module.scss";

type Props = {
  initialData: NameStepType;
  onClose: () => void;
  onSubmit: (data: Fields) => void;
  onPrevStep: () => void;
  activeStepIndex: number;
};

export const NameStep = (props: Props) => {
  const { onClose, activeStepIndex, onPrevStep, initialData, onSubmit } = props;
  const { t } = useTranslation();

  const form = useForm<Fields>({
    resolver: zodResolver(schema),
    defaultValues: initialData,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const body = (
    <div className={styles.rows}>
      <div className={styles.formControl}>
        <TextInput
          inputRootClassName={styles.inputRoot}
          label={t("roles_wizard.name.role_name")}
          testId="role-name-field"
          {...register("name")}
          error={!!errors?.name}
          helperText={errors?.name?.message}
          required
          showAsterisk
        />
      </div>
      <div className={styles.row}>
        <span className={styles.sample}>
          {t("roles_wizard.name.description")}
        </span>
        <TextArea
          testId="role-description-field"
          {...register("description")}
          className={styles.textarea}
        />
      </div>
    </div>
  );

  const onFormSubmit = (data: Fields) => {
    onSubmit(data);
  };

  return (
    <Step
      title={t("roles_wizard.name.title")}
      subtitle={t("roles_wizard.name.subtitle")}
      mainActionTitle={t("roles_wizard.privileges.title")}
      body={body}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
    />
  );
};
