import { useState } from "react";
import { useTranslation } from "react-i18next";

import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";
import { createRole } from "services/rbac/createRole";
import { deleteRole } from "services/rbac/deleteRole";
import { getGrantStatements, getRevokeStatements } from "services/rbac/utils";
import { useUsers } from "services/users/useUsers";
import { User } from "services/users/user.types";
import { getGrantStatement } from "services/users/utils";

import { Privilege } from "pages/govern/Roles/PrivilegesTransaction";

import { useCurrentParamsAccount } from "components/Account/useCurrentParamsAccount";
import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import ContextMenuItemsGroup from "components/ContextMenu/ContextMenuItemsGroup";
import { Search } from "components/LeftSidebar/Search";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { Step } from "components/Wizard/Step/Step";
import { queryClient } from "components/queryClient";

import { ANY_DATABASE, ANY_ENGINE } from "../PrivilegesTable/constant";
import { RolesDataType, RolesWizardStep } from "../types";

import styles from "./styles.module.scss";

type Props = {
  onClose: () => void;
  onPrevStep: () => void;
  activeStepIndex: number;
  rolesData: RolesDataType;
};

const types = ["account", "database", "engine", "schema", "table", "view"];

export const AssignStep = (props: Props) => {
  const { onClose, activeStepIndex, onPrevStep, rolesData } = props;
  const { t } = useTranslation();
  const { putStatusMessage } = useStatusMessageQueue();

  const currentAccount = useCurrentParamsAccount();
  const users = useUsers();

  const { name } = rolesData.name;
  const { privileges } = rolesData.privileges;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const filteredUsers = users.filter(({ userName }) =>
    userName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  const handleSelectUser = (user: User) => {
    const items = selectedUsers.includes(user.userName)
      ? selectedUsers.filter(u => u !== user.userName)
      : [...selectedUsers, user.userName];
    setSelectedUsers(items);
  };

  const body = (
    <div className={styles.wrapper}>
      <Search
        value={search}
        onChange={handleChangeSearch}
        placeholder={t("roles_wizard.assign.search_placeholder")}
        testId="roles-wizard-search-users"
        className={styles.search}
      />

      <ContextMenu>
        <ContextMenuItemsGroup
          isGroup
          maxHeight={300}
        >
          {filteredUsers.map(user => {
            return (
              <ContextMenuItem
                key={user.userName}
                value={user.userName}
                text={user.userName}
                direction="left"
                checked={selectedUsers.includes(user.userName)}
                onClick={() => {
                  handleSelectUser(user);
                }}
              />
            );
          })}
        </ContextMenuItemsGroup>
      </ContextMenu>
    </div>
  );

  const grantRoleToUsers = async () => {
    const { name } = rolesData[RolesWizardStep.name];
    for (const user of selectedUsers) {
      const statement = getGrantStatement(user, name);
      // eslint-disable-next-line no-await-in-loop
      await systemEngineEnvironment.execute(statement);
    }
  };

  const getName = (object: string | { name: string; catalogName: string }) => {
    if (typeof object === "string") {
      return object;
    }
    return object.name;
  };

  const getCatalogName = (
    object: string | { name: string; catalogName: string }
  ) => {
    if (typeof object === "string") {
      return undefined;
    }
    return object.catalogName;
  };

  const mapPrivilegeObjects = (
    type: string,
    actions: string[],
    objects: string[] | { name: string; catalogName: string }[]
  ) => {
    const privileges = objects.map(object => {
      const name = getName(object);
      const catalogName = getCatalogName(object);
      if (object === ANY_DATABASE || object === ANY_ENGINE) {
        return {
          type: "account",
          catalogName,
          resource: currentAccount.accountName as string,
          actions: actions.map(action => `${action} any ${type}`),
        };
      }
      return {
        type,
        resource: name,
        catalogName,
        actions,
      };
    });
    return privileges;
  };

  const getAndExecuteStatements = async (
    getStatements: (
      accountName: string,
      roleName: string,
      grant: Privilege
    ) => string[],
    privileges: {
      type: string;
      resource: string;
      catalogName: string | undefined;
      actions: string[];
    }[]
  ) => {
    for (const item of privileges) {
      const statements = getStatements(currentAccount.accountName, name, item);
      for (const statement of statements) {
        // eslint-disable-next-line no-await-in-loop
        await systemEngineEnvironment.execute(statement, {
          database: item.catalogName,
        });
      }
    }
  };

  const revokeGroupPrivileges = async (
    type: string,
    group: {
      toAssign: string[];
      toRevoke: string[];
      objects: string[] | { name: string; catalogName: string }[];
    }
  ) => {
    const { toRevoke, objects } = group;
    const revokePrivileges = mapPrivilegeObjects(type, toRevoke, objects);
    await getAndExecuteStatements(getRevokeStatements, revokePrivileges);
  };

  const grantGroupPrivileges = async (
    type: string,
    group: {
      toAssign: string[];
      toRevoke: string[];
      objects: string[] | { name: string; catalogName: string }[];
    }
  ) => {
    const { toAssign, objects } = group;
    const grantPrivileges = mapPrivilegeObjects(type, toAssign, objects);
    await getAndExecuteStatements(getGrantStatements, grantPrivileges);
  };

  const onFormSubmit = async () => {
    if (isLoading || !privileges) {
      return;
    }

    try {
      setIsLoading(true);
      await createRole(name);

      for (const type of types) {
        const privilegesGroups = privileges[type as keyof typeof privileges];
        for (const group of privilegesGroups) {
          // eslint-disable-next-line no-await-in-loop
          await revokeGroupPrivileges(type, group);
          // eslint-disable-next-line no-await-in-loop
          await grantGroupPrivileges(type, group);
        }
      }
      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });

      await grantRoleToUsers();
      onClose();
      putStatusMessage({
        message: t("roles.create_modal.success"),
        type: StatusMessageType.Success,
      });
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
      await deleteRole(name);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Step
      title={t("roles_wizard.assign.title")}
      subtitle={t("roles_wizard.assign.subtitle")}
      mainActionTitle={t("roles_wizard.assign.save")}
      body={body}
      onClose={onClose}
      onSubmit={onFormSubmit}
      activeStepIndex={activeStepIndex}
      onPrevStep={onPrevStep}
      disabledSubmit={isLoading || !selectedUsers.length}
    />
  );
};
