import { useSuspenseQuery } from "@tanstack/react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";

type Table = {
  tableName: string;
  tableType: string;
};

export const useViewNames = ({ database }: { database: string }): Table[] => {
  const { data } = useSuspenseQuery<Table[], Error>({
    queryKey: [ReactQueryKeysAccount.viewNames, database],
    queryFn: async () => {
      const result = await systemEngineEnvironment.execute<Table>(
        `SELECT table_name, view_definition FROM information_schema.views WHERE table_schema = 'public' order by table_name`,
        { database }
      );

      const [response] = result;
      return response.rows;
    },
  });
  return data || [];
};
