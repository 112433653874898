import { Dialog, DialogContent, DialogProps } from "@mui/material";
import cn from "classnames";
import React, { useCallback, useMemo, useRef } from "react";

import CloseIcon from "assets/icons/Close.svg?react";

import MaterialButton, { ButtonTemplate } from "components/Button";
import LoadingOverlap from "components/LoadingOverlap";

import styles from "./styles.module.scss";

export interface DialogComponentProps {
  openModal: boolean;
  closeModal(): void;

  title: string | JSX.Element;

  style?: string;

  maxWidth?: DialogProps["maxWidth"];

  showCloseIcon?: boolean;
  contentMaxWidth?: string;
  classes?: {
    root?: string;
    paper?: string;
    paperWidthMd?: string;
    dialogTitleCn?: string;
  };
  autoFocus?: boolean;
  dialogIsLoading?: boolean;
  testIds?: {
    contentWrapper?: string;
  };
  children: React.ReactNode;

  cancelButtonOptions?: {
    isLoading?: boolean;
    isDisabled?: boolean;
    text?: string;
  };
  confirmButtonOptions?: {
    isHidden?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    allowKeyboardConfirm?: boolean;
    confirmTooltip?: {
      title: string;
    };
    onConfirm?: () => void;
    text?: string;
  };
  secondaryButtonOptions?: {
    isLoading?: boolean;
    onClick?: () => void;
    text?: string;
  };
}

const DialogComponent: React.FC<DialogComponentProps> = props => {
  const {
    openModal,
    closeModal,

    children,
    cancelButtonOptions,
    confirmButtonOptions,
    secondaryButtonOptions,
    title,
    style,

    maxWidth,

    contentMaxWidth,
    autoFocus = true,
    classes = {},
    dialogIsLoading,
    testIds = {},
    showCloseIcon,
  } = props;

  const primaryButton = useRef<HTMLElement | null>(null);
  const secondaryButton = useRef<HTMLElement | null>(null);

  const handleDialogEnter = useCallback(() => {
    if (autoFocus) {
      if (primaryButton.current) {
        primaryButton.current.focus();
        return;
      }
      if (secondaryButton.current) {
        secondaryButton.current.focus();
      }
    }
  }, [autoFocus]);

  const { dialogTitleCn, ...dialogClasses } = classes;

  const TransitionProps = useMemo(() => {
    return {
      onEntered: handleDialogEnter,
    };
  }, [handleDialogEnter]);

  const handleCloseModal = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault();

      closeModal();
      return false;
    },
    [closeModal]
  );

  const handleKeyPress = (event: { key: string }) => {
    if (event.key.toLowerCase() === "enter") {
      const isConfirmButtonAvailable =
        !confirmButtonOptions?.isLoading && !confirmButtonOptions?.isDisabled;
      const canConfirmByEnter =
        confirmButtonOptions?.allowKeyboardConfirm &&
        isConfirmButtonAvailable &&
        confirmButtonOptions.onConfirm;

      return canConfirmByEnter && confirmButtonOptions
        ? confirmButtonOptions.onConfirm?.()
        : null;
    }
  };

  return (
    <Dialog
      open={openModal}
      classes={{
        ...dialogClasses,
        paper: cn(styles.paper, dialogClasses?.paper),
        paperWidthSm: style || styles.paperWidthSm,
        paperWidthMd: cn(styles.paperWidthMd, dialogClasses?.paperWidthMd),
      }}
      PaperProps={{
        style: contentMaxWidth ? { maxWidth: contentMaxWidth } : {},
      }}
      fullWidth
      maxWidth={maxWidth}
      onClose={handleCloseModal}
      TransitionProps={TransitionProps}
      onKeyPress={handleKeyPress}
    >
      <DialogContent
        classes={{
          root: styles.dialogContent,
        }}
      >
        <div
          className={styles.contentWrapper}
          data-testid={testIds?.contentWrapper}
        >
          <div className={cn(styles.dialogTitle, dialogTitleCn)}>
            <h2
              className={styles.titleText}
              data-testid="dialog-title"
            >
              {title}
            </h2>
            {showCloseIcon && (
              // button added as interactive element for automation tests
              <button
                className={styles.closeButton}
                data-testid="close-modal-button"
              >
                <CloseIcon
                  className={styles.closeIcon}
                  onClick={closeModal}
                  data-testid="close-modal-icon"
                />
              </button>
            )}
          </div>
          {dialogIsLoading && <LoadingOverlap isLoading />}
          {!dialogIsLoading && children}
          {!dialogIsLoading &&
            (confirmButtonOptions?.text ||
              secondaryButtonOptions?.text ||
              cancelButtonOptions?.text) && (
              <div className={styles.dialogActions}>
                {confirmButtonOptions?.text &&
                  !confirmButtonOptions.isHidden && (
                    <div className={styles.buttonContainer}>
                      <MaterialButton
                        ref={primaryButton}
                        text={confirmButtonOptions.text}
                        isLoading={confirmButtonOptions.isLoading}
                        disabled={confirmButtonOptions.isDisabled}
                        onClick={() =>
                          confirmButtonOptions?.onConfirm &&
                          confirmButtonOptions?.onConfirm()
                        }
                        dataTestid="confirm"
                        tooltip={confirmButtonOptions.confirmTooltip}
                        disablePortal
                      />
                    </div>
                  )}

                {secondaryButtonOptions?.text && (
                  <div className={styles.buttonContainer}>
                    <MaterialButton
                      ref={secondaryButton}
                      text={secondaryButtonOptions?.text}
                      isLoading={secondaryButtonOptions.isLoading}
                      template={ButtonTemplate.Tertiary}
                      onClick={() =>
                        secondaryButtonOptions?.onClick &&
                        secondaryButtonOptions?.onClick()
                      }
                      dataTestid="secondary"
                    />
                  </div>
                )}

                {cancelButtonOptions?.text && (
                  <div className={styles.buttonContainer}>
                    <MaterialButton
                      text={cancelButtonOptions?.text}
                      isLoading={cancelButtonOptions.isLoading}
                      disabled={cancelButtonOptions.isDisabled}
                      template={ButtonTemplate.Ghost}
                      onClick={() => closeModal()}
                      dataTestid="close-modal"
                    />
                  </div>
                )}
              </div>
            )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default DialogComponent;
