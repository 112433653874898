import { visibleItemsCount } from "./utils";

import styles from "./styles.module.scss";

type Props = {
  expanded?: boolean;
  items: string[];
  handleMore: (event: any) => void;
  title: string;
};

export const RenderSelectValue = (props: Props) => {
  const { expanded, items, handleMore, title } = props;
  if (!expanded && items.length > visibleItemsCount) {
    const moreCount = items.length - visibleItemsCount;
    const visibleItems = items.slice(0, visibleItemsCount);
    return (
      <div className={styles.value}>
        <div className={styles.value__items}>{visibleItems.join(" ")}</div>
        <div>
          <a onClick={handleMore}> +{moreCount}more</a>
        </div>
      </div>
    );
  }
  if (expanded && items.length >= 1) {
    return title;
  }
  return items.join(" ");
};
