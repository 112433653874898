import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactQueryKeysAccount } from "services/queryKeys";

import { RolesWizard } from "components/RolesWizard/RolesWizard";
import { StatusMessageType } from "components/StatusMessageQueue/StatusMessageQueueProvider";
import useStatusMessageQueue from "components/StatusMessageQueue/hooks/useStatusMessageQueue";
import { queryClient } from "components/queryClient";

export const useCreateRole = () => {
  const [role, setCreateRole] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const { putStatusMessage } = useStatusMessageQueue();

  const openCreateRole = () => {
    setCreateRole(true);
  };

  const closeCreateRole = () => {
    if (isLoading) {
      return;
    }
    setCreateRole(false);
  };

  const confirmCreate = async (_role: any) => {
    try {
      setIsLoading(true);
      // await createRole();

      await queryClient.invalidateQueries({
        queryKey: [ReactQueryKeysAccount.rbacRoles],
      });
      closeCreateRole();
      putStatusMessage({
        message: t("workspace.create_role.success"),
        type: StatusMessageType.Success,
      });
    } catch (error: any) {
      console.log(error);
      putStatusMessage({
        message: error.message,
        type: StatusMessageType.Error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createRoleMarkup = role ? (
    <RolesWizard
      onClose={closeCreateRole}
      onCreateRole={confirmCreate}
    />
  ) : null;

  return {
    createRoleMarkup,
    openCreateRole,
  };
};
