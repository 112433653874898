import { PrivilegesState } from "./types";

export const ROLES_WIZARD_ADD_GROUP_EMPTY = "ROLES_WIZARD_ADD_GROUP_EMPTY";
export const ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS =
  "ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS";
export const ROLES_WIZARD_DELETE_GROUP = "ROLES_WIZARD_DELETE_GROUP";

export const ROLES_WIZARD_SET_RESOURCE_OBJECTS =
  "ROLES_WIZARD_SET_RESOURCE_OBJECTS";

export const ROLES_WIZARD_SET_ASSIGN_PRIVILEGES =
  "ROLES_WIZARD_SET_ASSIGN_PRIVILEGES";
export const ROLES_WIZARD_SET_REVOKE_PRIVILEGES =
  "ROLES_WIZARD_SET_REVOKE_PRIVILEGES";

export const ROLES_WIZARD_TOGGLE_EXPAND_ROWS =
  "ROLES_WIZARD_TOGGLE_EXPAND_ROWS";

export const ROLES_WIZARD_TOGGLE_EXPAND_GROUP =
  "ROLES_WIZARD_TOGGLE_EXPAND_GROUP";

const setElementAtIndex = (
  array: unknown[],
  element: unknown,
  index: number
) => {
  return [
    ...array.slice(0, index),
    element,
    ...array.slice(index + 1, array.length),
  ];
};

export const privilegesReducer = (state: PrivilegesState, action: any) => {
  switch (action.type) {
    case ROLES_WIZARD_ADD_GROUP_EMPTY: {
      const { resource, objects = [] } = action;
      const resourceGroups = state[resource as keyof typeof state];
      const newGroup = {
        objects,
        toAssign: [],
        toRevoke: [],
      };
      return {
        ...state,
        [resource]: [...resourceGroups, newGroup],
      };
    }
    case ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS: {
      const { resource, allObjects } = action as {
        resource: string;
        allObjects: string[];
      };
      const resourceGroups = state[resource as keyof typeof state];
      const affectedObjects = new Set();

      for (const group of resourceGroups) {
        const { objects } = group;
        objects.forEach(object => affectedObjects.add(object));
      }
      const remainingObjects = allObjects.filter(object => {
        return !affectedObjects.has(object);
      });
      return {
        ...state,
        [resource]: [
          ...resourceGroups,
          { objects: remainingObjects, toAssign: [], toRevoke: [] },
        ],
      };
    }
    case ROLES_WIZARD_DELETE_GROUP: {
      const { resource, index } = action;
      const resourceGroups = state[resource as keyof typeof state];
      const newGroups = [
        ...resourceGroups.slice(0, index),
        ...resourceGroups.slice(index + 1, resourceGroups.length),
      ];
      return {
        ...state,
        [resource]: newGroups,
      };
    }
    case ROLES_WIZARD_SET_RESOURCE_OBJECTS: {
      const { resource, objects, index } = action;
      const groups = state[resource as keyof typeof state];
      const resourceGroup = groups[index];

      const group = {
        ...resourceGroup,
        objects,
      };

      const newGroups = setElementAtIndex(groups, group, index);

      return {
        ...state,
        [resource]: newGroups,
      };
    }
    case ROLES_WIZARD_SET_ASSIGN_PRIVILEGES: {
      const { resource, privileges, index } = action;
      const groups = state[resource as keyof typeof state];
      const resourceGroup = groups[index];

      const group = {
        ...resourceGroup,
        toAssign: privileges,
      };

      const newGroups = setElementAtIndex(groups, group, index);

      return {
        ...state,
        [resource]: newGroups,
      };
    }
    case ROLES_WIZARD_SET_REVOKE_PRIVILEGES: {
      const { resource, privileges, index } = action;
      const groups = state[resource as keyof typeof state];
      const resourceGroup = groups[index];

      const group = {
        ...resourceGroup,
        toRevoke: privileges,
      };

      const newGroups = setElementAtIndex(groups, group, index);

      return {
        ...state,
        [resource]: newGroups,
      };
    }
    case ROLES_WIZARD_TOGGLE_EXPAND_GROUP: {
      const { index, resource } = action;
      const groups = state[resource as keyof typeof state];
      const resourceGroup = groups[index];
      const group = {
        ...resourceGroup,
        expanded: !resourceGroup.expanded,
      };
      const newGroups = setElementAtIndex(groups, group, index);
      return {
        ...state,
        [resource]: newGroups,
      };
    }
    case ROLES_WIZARD_TOGGLE_EXPAND_ROWS: {
      const anyExpanded = Object.keys(state).reduce((acc, resource) => {
        const resourceGroups = state[resource as keyof typeof state];
        for (const group of resourceGroups) {
          if (group.expanded) {
            // eslint-disable-next-line no-param-reassign
            acc = true;
          }
        }
        return acc;
      }, false);

      const newState: any = {};
      for (const resource of Object.keys(state)) {
        const resourceGroups = state[resource as keyof typeof state];
        const toggledResourceGroups = resourceGroups.map(group => {
          return {
            ...group,
            expanded: !anyExpanded,
          };
        });
        newState[resource as keyof typeof newState] = toggledResourceGroups;
      }

      return newState;
    }
    default: {
      return state;
    }
  }
};
