import React, { useCallback } from "react";

import styles from "./HeaderName.module.scss";

interface Props {
  text: string;
  // todo: fix any
  headerItemRef?: React.Ref<any>;
  onClick?: () => void;
  iconComponent?: React.ReactNode | null;
  filtersDropdown?: React.ReactNode | null;
  draggingComponents?: React.ReactNode | null;
}
export const CommonHeaderName = (props: Props) => {
  const {
    text,
    headerItemRef,
    onClick,
    iconComponent,
    filtersDropdown,
    draggingComponents,
  } = props;

  const handleHeaderTextMouseDown = useCallback(
    (event: React.SyntheticEvent) => {
      event.nativeEvent.stopImmediatePropagation();
      event.stopPropagation();
    },
    []
  );

  return (
    <div
      className={styles.header}
      title={text}
      data-role="name"
      ref={headerItemRef}
      onClick={onClick}
      data-testid={`sort-column-${text}`}
    >
      <div
        className={styles.header__name}
        data-testid="datagrid-header-cell-name"
        onMouseDown={handleHeaderTextMouseDown}
      >
        <div className={styles.name}>
          <span className={styles.name__text}>{text}</span>
        </div>
        {iconComponent}
        {filtersDropdown}
      </div>
      {draggingComponents}
    </div>
  );
};
