import _startCase from "lodash/startCase";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useWorkspaceEngines } from "services/engines/useWorkspaceEngines";

import { useMenu } from "components/ActionMenu/useMenu";
import ContextMenuDivider from "components/ContextMenu/ContextMenuDivider";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import {
  ROLES_WIZARD_ADD_GROUP_EMPTY,
  ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
  ROLES_WIZARD_SET_RESOURCE_OBJECTS,
} from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";
import { ALL_ENGINES, ANY_ENGINE } from "./constant";
import { usePrivilegesHandlers } from "./usePrivilegesHandlers";
import { getOtherGroupsObjects, visibleItemsCount } from "./utils";

import styles from "./styles.module.scss";

const privileges = ["USAGE", "OPERATE", "MODIFY"];

type Props = {
  index: number;
  resource: string;
};

export const EnginesGroup = (props: Props) => {
  const { index, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const menu = useMenu();
  const { data: engines = [] } = useWorkspaceEngines();

  const { t } = useTranslation();

  const {
    onGrantPrivileges,
    onRevokePrivileges,
    onSelectResourceObjects,
    deleteGroup,
    handleMore,
  } = usePrivilegesHandlers({ index, resource });

  const groupState = privilegesState.engine[index];

  const otherGroupsObjects = getOtherGroupsObjects(
    privilegesState.engine,
    index
  );

  const { expanded, objects, toAssign, toRevoke } = groupState;

  const dependencies = useRef({
    index,
    privilegesDispatch,
    resource,
    objects,
  });

  useEffect(() => {
    const { index, privilegesDispatch, resource, objects } =
      dependencies.current;
    if (!objects.length) {
      privilegesDispatch({
        type: ROLES_WIZARD_SET_RESOURCE_OBJECTS,
        resource,
        objects: [ALL_ENGINES],
        index,
      });
    }
  }, [dependencies]);

  const items = [
    [
      {
        key: "add_remaining",
        text: t("roles_wizard.privileges.menu.add_remaining_engines"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_WITH_REMAINING_OBJECTS,
            resource,
            allObjects: engines.map(({ engineName }) => engineName),
          });
        },
        testId: "roles-wizard-add-remaining-engines",
      },
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
            objects: [ALL_ENGINES],
          });
        },
        testId: "roles-wizard-add-engines-group",
      },
    ],

    ...(privilegesState.engine.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: deleteGroup,
              testId: "roles-wizard-delete-engines-group",
            },
          ],
        ]
      : []),
  ];

  const selectedItems = [
    <ContextMenuItem
      value={ALL_ENGINES}
      key={ALL_ENGINES}
      checked={objects.includes(ALL_ENGINES)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.all_engines")}
      direction="left"
      bulkItem
      skipFilter
    />,
    <ContextMenuItem
      value={ANY_ENGINE}
      key={ANY_ENGINE}
      checked={objects.includes(ANY_ENGINE)}
      checkedIconPlaceholder={true}
      text={t("roles_wizard.privileges.any_engine")}
      direction="left"
      bulkItem
      skipFilter
    />,
    <ContextMenuDivider key="divider" />,
    ...engines.map(engine => {
      const { engineName } = engine;
      return (
        <ContextMenuItem
          value={engineName}
          key={engineName}
          checked={objects.includes(engineName)}
          disabled={otherGroupsObjects[engineName]}
          checkedIconPlaceholder={true}
          text={engineName}
          direction="left"
        />
      );
    }),
  ];

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase()),
    };
  });

  const renderSpecialValue = (value: string) => {
    if (value === ALL_ENGINES) {
      return t("roles_wizard.privileges.all_engines");
    }
    if (value === ANY_ENGINE) {
      return t("roles_wizard.privileges.any_engine");
    }
    return value;
  };

  return (
    <>
      <div className={styles.group}>
        <OutlinedSelect
          wrapperClassName={styles.select__objects}
          multiple
          onSelect={onSelectResourceObjects}
          controlledValue={objects}
          renderValue={items => {
            if (!expanded && items.length > visibleItemsCount) {
              const moreCount = items.length - visibleItemsCount;
              const visibleItems = items.slice(0, visibleItemsCount);
              return (
                <div className={styles.value}>
                  <div className={styles.value__items}>
                    {visibleItems.join(" ")}
                  </div>
                  <div>
                    <a onClick={handleMore}> +{moreCount}more</a>
                  </div>
                </div>
              );
            }
            if (expanded && items.length > 1) {
              return t("roles_wizard.privileges.selected_engines");
            }
            return items.map(item => renderSpecialValue(item)).join(" ");
          }}
          allowUncheck
          searchOptions={{
            searchPlaceholder: t("roles_wizard.privileges.search_engine"),
            noResultsText: t("roles_wizard.privileges.no_engine_found"),
          }}
          noBorder
        >
          {selectedItems}
        </OutlinedSelect>
        <GrantPrivilegesSelector
          onChange={onGrantPrivileges}
          privileges={privilegesOptions}
          selectedPrivileges={toAssign}
        />

        <RevokePrivilegeSelector
          onChange={onRevokePrivileges}
          privileges={privilegesOptions}
          selectedPrivileges={toRevoke}
        />

        <Menu
          items={items}
          menu={menu}
          alwaysVisible
        />
      </div>
      {expanded && (
        <div className={styles.objects}>
          {objects.map(object => {
            const value = renderSpecialValue(object);
            return (
              <div
                className={styles.object}
                key={object}
              >
                {value}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
