import { PostgreSQL, SQLDialect, sql } from "@codemirror/lang-sql";
import { EditorState } from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import { basicSetup } from "codemirror";
import * as React from "react";

import "pages/DevelopWorkspace/Editor/CodeMirror/codemirror.scss";
import { FIREBOLT_KEYWORDS } from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/constants";

interface ReadOnlyEditorProps {
  value: string;
  className?: string;
}

const ReadOnlyEditor = (props: ReadOnlyEditorProps) => {
  const { value, className } = props;
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const node = ref.current;
    if (node === null) {
      return;
    }
    const editor = new EditorView({
      state: EditorState.create({
        extensions: [
          EditorState.readOnly.of(true),
          sql({
            dialect: SQLDialect.define({
              ...PostgreSQL.spec,
              keywords: FIREBOLT_KEYWORDS,
            }),
          }),
          basicSetup,
        ],
      }),
      parent: node,
    });
    editor.dispatch({
      changes: {
        from: 0,
        to: editor.state.doc.length,
        insert: value,
      },
    });
  }, [value]);

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      ref={ref}
      className={className}
    />
  );
};

export default ReadOnlyEditor;
