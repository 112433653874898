import { systemEngineEnvironment } from "services/environment/systemEngine";

import { WorkspaceEngine, WorkspaceEngineType } from "./engine.types";

export type EditEngineInput = {
  engineName: string;
  type: WorkspaceEngineType;
  nodes: string;
  autoStop: string;
  autoStart: boolean;
  clusters: string;
};

export const editWorkspaceEngine = async ({
  engine,
  initialEngine,
}: {
  engine: EditEngineInput;
  initialEngine: WorkspaceEngine;
}) => {
  if (engine.engineName !== initialEngine.engineName) {
    await systemEngineEnvironment.execute(
      `ALTER ENGINE "${initialEngine.engineName}" RENAME TO "${engine.engineName}";`
    );
  }

  if (engine.autoStop !== initialEngine.autoStop) {
    const autoStopPart = systemEngineEnvironment.formatter.formatQuery(
      `ALTER ENGINE "${engine.engineName}" SET
AUTO_STOP = ?
`,
      [Number(engine.autoStop)]
    );
    await systemEngineEnvironment.execute(autoStopPart);
  }

  if (engine.autoStart !== initialEngine.autoStart) {
    const autoStartPart = systemEngineEnvironment.formatter.formatQuery(
      `ALTER ENGINE "${engine.engineName}" SET
AUTO_START = ?
`,
      [engine.autoStart]
    );
    await systemEngineEnvironment.execute(autoStartPart);
  }

  if (engine.clusters !== initialEngine.clusters) {
    const clustersPart = systemEngineEnvironment.formatter.formatQuery(
      `ALTER ENGINE "${engine.engineName}" SET
CLUSTERS = ?
`,
      [Number(engine.clusters)]
    );
    await systemEngineEnvironment.execute(clustersPart);
  }

  if (
    engine.type !== initialEngine.type ||
    engine.nodes !== initialEngine.nodes
  ) {
    const engineSql = systemEngineEnvironment.formatter.formatQuery(
      `ALTER ENGINE "${engine.engineName}" SET
TYPE = "${engine.type}"
NODES = ?
`,
      [Number(engine.nodes)]
    );
    await systemEngineEnvironment.execute(engineSql);
  }
};
