import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { WorkspaceEngine } from "services/engines/engine.types";
import { systemEngineEnvironment } from "services/environment/systemEngine";

import { SYSTEM_ENGINE } from "pages/DevelopWorkspace/workspace.constants";

import { useCurrentAccount } from "components/Account/useCurrentAccount";

import { ReactQueryKeysAccount } from "../queryKeys";

export const useWorkspaceEngines = (includeSystemEngine?: boolean) => {
  const { getAccount } = useCurrentAccount();
  const { data, isLoading, error } = useSuspenseQuery<WorkspaceEngine[], Error>(
    {
      queryKey: [ReactQueryKeysAccount.workspaceEngines],
      queryFn: async () => {
        const result = await systemEngineEnvironment.execute<WorkspaceEngine>(
          "select * from information_schema.engines order by lower(engine_name);"
        );
        const [response] = result;
        return response.rows;
      },
    }
  );

  const engines = useMemo(() => {
    const engines = data ? [...data] : undefined;

    if (includeSystemEngine && engines) {
      const account = getAccount();

      if (!account) {
        throw new Error("Account is not defined");
      }

      const systemEngineUrl =
        systemEngineEnvironment.getApiEndpoint().replace(/^https?:\/\//, "") +
        "/query";

      engines.unshift({
        ...SYSTEM_ENGINE,
        url: systemEngineUrl,
      });
    }

    return engines;
  }, [data, includeSystemEngine, getAccount]);

  return { data: engines, isLoading, error };
};
