import { Popover } from "@mui/material";
import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Resizable } from "react-resizable";

import InsertCodeIcon from "assets/icons/InsertCodeIcon.svg?react";

import DocumentEditor from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentEditor/DocumentEditor";
import RunButton from "pages/DevelopWorkspace/Editor/Document/DocumentContent/RunButton/RunButton";
import FlagsMenu from "pages/DevelopWorkspace/Editor/Document/FlagsMenu";
import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";
import { useScripts } from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";
import { isDocumentRunning } from "pages/DevelopWorkspace/helpers/isDocumentRunning";
import {
  CancellationStatus,
  ExecutionType,
  QueryStatementStatus,
  WorkspaceDocument,
} from "pages/DevelopWorkspace/workspace.types";

import ContextMenu from "components/ContextMenu/ContextMenu";
import ContextMenuHeader from "components/ContextMenu/ContextMenuHeader";
import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { useLayoutToggles } from "components/LayoutToggles/context";

import { QUERY_TEMPLATES, SCRIPT_ACTIONS } from "./constants/script-templates";

import styles from "./DocumentContent.module.scss";

interface DocumentContentProps {
  document: WorkspaceDocument;
}

const HEADER_HEIGHT_PX = 49;
const FOOTER_HEIGHT_PX = 48;

const adjustEditorHeight = (height?: number) => {
  // if height is not provided, use the 100%

  const editor = window.document.querySelector(".cm-editor");
  if (!editor) {
    return;
  }

  if (height) {
    editor.setAttribute("style", `height: ${height - FOOTER_HEIGHT_PX}px`);
  } else {
    editor.setAttribute("style", `height: 100%`);
  }
};

const DocumentContent = (props: DocumentContentProps) => {
  const { document } = props;
  const { state: scriptsState } = useScripts();
  const {
    actions: {
      updateDocumentContent,
      startDocumentExecution,
      changeDocumentContext,
      cancelDocumentExecution,
      updateDocumentSelection,
      changeDocumentLayout,
    },
  } = useDocuments();
  const { activeEditorView, insertTextAtCursorPosition, setActiveEditorView } =
    useActiveEditorView();

  const { layout } = useLayoutToggles();
  const editorHeightPx = document.layout.editorHeightPx;
  const rootRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(editorHeightPx);
  const [executionType, setExecutionType] = React.useState<ExecutionType>(
    ExecutionType.Query
  );

  const expanded = layout.results.expanded && document.execution;
  const footerVisible = !!document.execution;

  useEffect(() => {
    setHeight(document.layout.editorHeightPx);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on document ID change
  }, [document.id]);

  useEffect(() => {
    if (expanded) {
      adjustEditorHeight(height);
    } else {
      adjustEditorHeight();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only need to run when layout.results.expanded changes
  }, [expanded]);

  const scriptActionsMenuAnchorEl = useRef<HTMLDivElement | null>(null);

  const [scriptActionsMenuOpen, setScriptActionsMenuOpen] =
    useState<boolean>(false);

  const script = getDocumentScript(document, scriptsState);

  if (script === null) {
    return null;
  }

  const isRunning = isDocumentRunning(document);
  const isUnknown = document.execution?.queryStatements?.some(
    queryStatement => queryStatement.status === QueryStatementStatus.unknown
  );

  const isCanceling =
    document.execution?.cancellationStatus === CancellationStatus.Initiated ||
    document.execution?.cancellationStatus === CancellationStatus.Pending;

  const cancelDocument = () => {
    if (
      document.execution?.cancellationStatus === CancellationStatus.Initiated ||
      document.execution?.cancellationStatus === CancellationStatus.Pending
    ) {
      return;
    }

    cancelDocumentExecution(document.id);
  };

  const runDocument = () => {
    if (isRunning) {
      cancelDocument();
    }

    if (!activeEditorView) {
      return;
    }

    const docText = activeEditorView.state.doc.toString();

    if (docText === "") {
      return;
    }

    const range = activeEditorView.state.selection.ranges[0];

    if (range.from === range.to) {
      // no selection, run the whole document
      startDocumentExecution(document.id, docText, executionType);
      return;
    }

    const selectedText = activeEditorView.state.doc.sliceString(
      range.from,
      range.to
    );
    startDocumentExecution(document.id, selectedText, executionType);
  };

  const handleClick = (type: SCRIPT_ACTIONS) => () => {
    setScriptActionsMenuOpen(false);

    if (type === SCRIPT_ACTIONS.IMPORT_FILE) {
      return;
    }

    const snippet = QUERY_TEMPLATES[type];
    insertTextAtCursorPosition(snippet);
  };

  const handleRunCommand = (content: string) => {
    if (isUnknown || isCanceling) {
      return;
    }
    if (!isRunning && content) {
      startDocumentExecution(document.id, content, executionType);
    }
  };

  const handleChangeExecutionType = (type: ExecutionType) => {
    setExecutionType(type);
  };

  return (
    <>
      <Resizable
        width={Infinity}
        onResize={(event, { size }) => {
          if (height !== size.height) {
            setHeight(size.height);
          }
        }}
        onResizeStop={(event, { size }) => {
          adjustEditorHeight(size.height);

          changeDocumentLayout(document.id, {
            editorHeightPx: size.height,
          });
        }}
        axis="y"
        height={height}
        minConstraints={[Infinity, 150]}
        maxConstraints={[Infinity, 600]}
        handle={
          <div
            className={cn(styles.resizeHandler, {
              [styles.hidden]: !expanded,
            })}
          />
        }
        resizeHandles={["s"]}
      >
        <div
          className={cn(styles.root, {
            [styles.noOutputBlock]: !footerVisible,
          })}
          ref={rootRef}
          style={{
            height: expanded
              ? `${height}px`
              : `calc(100% - ${HEADER_HEIGHT_PX}px - ${
                  footerVisible ? FOOTER_HEIGHT_PX : 0
                }px)`,
          }}
        >
          <div className={styles.body}>
            <DocumentEditor
              key={document.id}
              initialContent={script.content}
              initialSelection={document.selection}
              onChange={(content: string, selection: [number, number]) => {
                if (content === script.content) return;

                updateDocumentContent(document.id, content);
                updateDocumentSelection(document.id, selection);
              }}
              onCursorActivity={selection => {
                updateDocumentSelection(document.id, selection);
              }}
              onInit={cm => {
                setActiveEditorView(cm);

                adjustEditorHeight(height);
              }}
              onRun={handleRunCommand}
            />
          </div>

          <div className={styles.footer}>
            <div className={styles.runButtonContainer}>
              <RunButton
                executionType={executionType}
                handleChangeExecutionType={handleChangeExecutionType}
                isRunning={isRunning}
                isUnknown={isUnknown}
                isCanceling={isCanceling}
                onClick={() => {
                  if (isUnknown || isCanceling) {
                    return;
                  }
                  if (isRunning) {
                    cancelDocument();
                    return;
                  }

                  runDocument();
                }}
              />
            </div>

            <div className={styles.controlIconsWrapper}>
              {Object.keys(document.context.settings).length > 0 && (
                <div className={styles.control}>
                  <FlagsMenu
                    settings={document.context.settings}
                    isDocumentRunning={isRunning}
                    onRemoveAll={() => {
                      changeDocumentContext(document.id, {
                        settings: {},
                      });
                    }}
                    onRemove={flag => {
                      const newSettings = { ...document.context.settings };
                      delete newSettings[flag];

                      changeDocumentContext(document.id, {
                        settings: {
                          ...newSettings,
                        },
                      });
                    }}
                  />
                </div>
              )}

              <div
                className={styles.control}
                ref={scriptActionsMenuAnchorEl}
                data-testid="script-actions-menu-icon"
                onClick={e => {
                  e.stopPropagation();
                  setScriptActionsMenuOpen(true);
                }}
              >
                <InsertCodeIcon />
              </div>
            </div>
          </div>
        </div>
      </Resizable>
      {scriptActionsMenuOpen && (
        <Popover
          open={scriptActionsMenuOpen}
          sx={{ transform: "translate(-4px, -4px)" }}
          anchorEl={scriptActionsMenuAnchorEl.current}
          onClose={() => {
            setScriptActionsMenuOpen(false);
          }}
          onClick={e => {
            e.stopPropagation();
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ContextMenu>
            <ContextMenuHeader text="Insert SQL template:" />
            <ContextMenuItem
              key="fact"
              text="New fact table"
              testId="new-fact-table"
              onClick={handleClick(SCRIPT_ACTIONS.NEW_FACT_TABLE)}
            />
            <ContextMenuItem
              key="dimension"
              text="New dimension table"
              testId="new-dimension-table"
              onClick={handleClick(SCRIPT_ACTIONS.NEW_DIMENSION_TABLE)}
            />
            <ContextMenuItem
              key="agg_index"
              text="Create agg index"
              testId="create-agg-index"
              onClick={handleClick(SCRIPT_ACTIONS.CREATE_AGG_INDEX)}
            />
            <ContextMenuItem
              key="query_history"
              text="Query history"
              testId="create-query-history"
              onClick={handleClick(SCRIPT_ACTIONS.QUERY_HISTORY)}
            />
            <ContextMenuItem
              key="import_data"
              text="Import data"
              testId="import-data"
              onClick={handleClick(SCRIPT_ACTIONS.IMPORT)}
            />
            {/* <ContextMenuDivider key="divider" />
            <ContextMenuItem
              key="keyboard-shortcuts"
              text="Keyboard shortcuts"
              secondaryText="Ctrl + Shift + ?"
              onClick={e => {
                e.stopPropagation();
                setScriptActionsMenuOpen(false);
              }}
            /> */}
          </ContextMenu>
        </Popover>
      )}
    </>
  );
};

export default DocumentContent;
