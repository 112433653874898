import z from "zod";

export const schema = z.object({
  name: z
    .string()
    .regex(/^[a-zA-Z0-9-]+$/, {
      message: "Should contain only letters, numbers and -",
    }),
  description: z.string().optional(),
});

export type Fields = z.infer<typeof schema>;
