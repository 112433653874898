import _startCase from "lodash/startCase";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { useDatabasesNames } from "services/databases/useDatabasesNames";

import { useMenu } from "components/ActionMenu/useMenu";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import { ROLES_WIZARD_ADD_GROUP_EMPTY } from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { getDatabaseItems } from "./DatabaseItems";
import { GrantPrivilegesSelector } from "./GrantPrivilegesSelector";
import { RevokePrivilegeSelector } from "./RevokePrivilegeSelector";
import { SchemasItems } from "./SchemasItems";
import { RenderSelectValue } from "./SelectValue";
import { usePrivilegesHandlers } from "./usePrivilegesHandlers";

import styles from "./styles.module.scss";

const privileges = [
  "USAGE",
  "MODIFY",
  "CREATE",
  "DELETE_ANY",
  "INSERT ANY",
  "UPDATE ANY",
  "TRUNCATE ANY",
  "VACUUM ANY",
  "MODIFY ANY",
  "SELECT ANY",
];

type Props = {
  index: number;
  resource: string;
};

export const SchemasGroup = (props: Props) => {
  const { index, resource } = props;
  const { privilegesState, privilegesDispatch } = usePrivilegesState();

  const databases = useDatabasesNames();
  const groupState = privilegesState.schema[index];
  const { expanded, toAssign, toRevoke, objects } = groupState;
  const menu = useMenu();
  const popoverRef = useRef<HTMLDivElement>(null);

  const { onGrantPrivileges, onRevokePrivileges, handleMore, deleteGroup } =
    usePrivilegesHandlers({
      index,
      resource,
    });

  const { t } = useTranslation();

  const items = [
    [
      {
        key: "add_group",
        text: t("roles_wizard.privileges.menu.add"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_ADD_GROUP_EMPTY,
            resource,
            objects: [],
          });
        },
        testId: "roles-wizard-add-schemas-group",
      },
    ],
    ...(privilegesState.schema.length > 1
      ? [
          [
            {
              key: "delete",
              text: t("roles_wizard.privileges.menu.delete"),
              action: deleteGroup,
              testId: "roles-wizard-delete-schema-group",
            },
          ],
        ]
      : []),
  ];

  const databaseItems = getDatabaseItems({
    databases,
    popoverRef,
    renderChildren: (catalogName: string) => (
      <SchemasItems
        catalogName={catalogName}
        index={index}
        resource={resource}
      />
    ),
  });

  const selectedSchemas = [
    ...new Set<string>(objects.map(object => object.name)),
  ];

  const privilegesOptions = privileges.map(privilege => {
    return {
      value: privilege,
      text: _startCase(privilege.toLowerCase()),
    };
  });

  return (
    <>
      <div className={styles.group}>
        <OutlinedSelect
          wrapperClassName={styles.select__objects}
          multiple
          controlledValue={selectedSchemas}
          renderValue={items => (
            <RenderSelectValue
              items={items}
              expanded={expanded}
              handleMore={handleMore}
              title={t("roles_wizard.privileges.selected_schemas")}
            />
          )}
          allowUncheck
          searchOptions={{
            searchPlaceholder: t("roles_wizard.privileges.search_database"),
            noResultsText: t("create_user.privileges.no_database_found"),
          }}
          noBorder
        >
          {databaseItems}
        </OutlinedSelect>
        <GrantPrivilegesSelector
          onChange={onGrantPrivileges}
          privileges={privilegesOptions}
          selectedPrivileges={toAssign}
        />

        <RevokePrivilegeSelector
          onChange={onRevokePrivileges}
          privileges={privilegesOptions}
          selectedPrivileges={toRevoke}
        />

        <Menu
          items={items}
          menu={menu}
          alwaysVisible
        />
      </div>

      {expanded && (
        <div className={styles.objects}>
          {objects.map(object => {
            const { name } = object;
            return (
              <div
                className={styles.object}
                key={name}
              >
                {name}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
