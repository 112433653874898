import { useTranslation } from "react-i18next";

import { useCurrentUser } from "services/users/useCurrentUser";

import ColumnIcon from "assets/icons/workspace/Column.svg?react";

import useActiveEditorView from "pages/DevelopWorkspace/contexts/ActiveEditorViewContext/hooks/useActiveEditorView";
import useActiveDocument from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useActiveDocument";
import { useDocuments } from "pages/DevelopWorkspace/contexts/DocumentsContext/hooks/useDocuments";

import { useMenu } from "components/ActionMenu/useMenu";
import { RecordLabelWithIcon } from "components/LeftSidebar/RecordLabelWithIcon";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { TreeNodeType } from "components/Tree/types";

import styles from "./styles.module.scss";

type Props = {
  node: TreeNodeType;
};

export const ColumnLabel = (props: Props) => {
  const { node } = props;
  const { label, payload } = node;
  const {
    actions: { createDocument },
  } = useDocuments();
  const activeDocument = useActiveDocument();
  const menu = useMenu();
  const { insertTextAtCursorPosition } = useActiveEditorView();
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  const items = [
    [
      {
        key: "insert",
        text: t("workspace.column_menu.insert"),
        shortcut: "Shift + click",
        action: () => {
          if (activeDocument) {
            insertTextAtCursorPosition(label);
          }
        },
        testId: "insert-column-in-editor-item",
      },
      {
        key: "preview",
        text: t("workspace.column_menu.preview"),
        action: () => {
          const name = `${node.payload.tableSchema}.${node.payload.tableName}`;
          const value = `select ${label} from ${name} limit 100`;
          if (activeDocument) {
            const getEngineName = () => {
              if (
                currentUser?.defaultEngine &&
                activeDocument?.context.engineName === "system"
              ) {
                return currentUser.defaultEngine;
              }
              return activeDocument.context.engineName;
            };
            createDocument(
              {
                title: `${node.payload.tableName}.${label}`,
                content: value,
                context: {
                  databaseName: node.databaseName,
                  engineName: getEngineName(),
                },
              },
              {
                autoRun: true,
              }
            );
          }
        },
        testId: "column-preview-item",
      },
    ],
  ];

  const dataType = payload?.dataType ? (
    <span className={styles.columnDataType}>{payload.dataType}</span>
  ) : null;
  return (
    <>
      <RecordLabelWithIcon
        noIndent
        icon={<ColumnIcon />}
        label={
          <>
            <span className={styles.columnLabel}>{label}</span>
            {dataType}
          </>
        }
        testId={`column-label-${label}`}
      />
      <Menu
        testId="column-more-icon"
        items={items}
        menu={menu}
      />
    </>
  );
};
