import { Suspense } from "react";

import DocumentContent from "pages/DevelopWorkspace/Editor/Document/DocumentContent/DocumentContent";
import DocumentContextSelector from "pages/DevelopWorkspace/Editor/Document/DocumentContextSelector/DocumentContextSelector";
import DocumentOutput from "pages/DevelopWorkspace/Editor/Document/DocumentOutput/DocumentOutput";
import { useScripts } from "pages/DevelopWorkspace/contexts/ScriptsContext/ScriptsContext";
import { getDocumentScript } from "pages/DevelopWorkspace/helpers/getDocumentScript";
import { WorkspaceDocument } from "pages/DevelopWorkspace/workspace.types";

import styles from "./Document.module.scss";

interface DocumentProps {
  document: WorkspaceDocument;
}

const Document = (props: DocumentProps) => {
  const { document } = props;
  const { state: scriptsState } = useScripts();

  const script = getDocumentScript(document, scriptsState);

  return (
    <div className={styles.root}>
      {script && (
        <>
          <Suspense
            fallback={<div className={styles.contextSelectorPlaceholder} />}
          >
            <DocumentContextSelector document={document} />
          </Suspense>
          <DocumentContent
            key={document.id}
            document={document}
          />

          <DocumentOutput document={document} />
        </>
      )}
    </div>
  );
};

export default Document;
