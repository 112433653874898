import _get from "lodash/get";
import _set from "lodash/set";
import { createContext, useContext } from "react";

import { SqlRbacAction } from "services/rbac/action";

const engineRelatedActionMap = {
  [SqlRbacAction.MODIFY]: SqlRbacAction.ENGINE_MODIFY_ANY,
  [SqlRbacAction.USAGE]: SqlRbacAction.ENGINE_USAGE_ANY,
  [SqlRbacAction.OPERATE]: SqlRbacAction.ENGINE_OPERATE_ANY,
};

const databaseRelatedActionMap = {
  [SqlRbacAction.MODIFY]: SqlRbacAction.DATABASE_MODIFY_ANY,
  [SqlRbacAction.USAGE]: SqlRbacAction.DATABASE_USAGE_ANY,
};

const relatedActionMap = {
  engine: engineRelatedActionMap,
  database: databaseRelatedActionMap,
};

type Options = {
  owner?: string;
};

class AccessManager {
  privileges!: Record<string, unknown>;

  accountName!: string;

  user!: { userName: string };

  isAllowedTo = (
    objectType: string,
    objectName: string,
    action: SqlRbacAction,
    options?: Options
  ) => {
    const resourcePrivileges = _get(
      this.privileges,
      [objectType, objectName],
      []
    );

    const accountPrivileges = _get(
      this.privileges,
      ["account", this.accountName],
      []
    );

    const wildcardPrivileges = _get(this.privileges, ["account", "*"], []);

    const relatedAction = _get(relatedActionMap, [objectType, action]);

    if (relatedAction) {
      return (
        resourcePrivileges.includes(action) ||
        accountPrivileges.includes(relatedAction) ||
        wildcardPrivileges.includes(relatedAction)
      );
    }
    const isAllowed =
      resourcePrivileges.includes(action) ||
      accountPrivileges.includes(action) ||
      wildcardPrivileges.includes(action);

    if (isAllowed) {
      return true;
    }

    if (options?.owner) {
      return this.user.userName === options.owner;
    }
    return false;
  };

  initAccessManager = async (
    privileges: any[],
    accountName: string,
    user: { userName: string }
  ) => {
    const byResource = {};
    for (const privilege of privileges) {
      const { objectType, objectName, privilegeType } = privilege;
      _set(
        byResource,
        [objectType, objectName],
        [..._get(byResource, [objectType, objectName], []), privilegeType]
      );
    }
    this.privileges = byResource;
    this.accountName = accountName;
    this.user = user;
  };
}

export const accessManager = new AccessManager();

export const AccessManagerContext = createContext(accessManager);

export const useAccessManager = () => {
  const accessManager = useContext(AccessManagerContext);
  return accessManager;
};
