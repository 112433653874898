import classNames from "classnames";

import styles from "./styles.module.scss";

type Props = {
  noIndent?: boolean;
  className?: string;
  icon?: React.ReactElement;
  label?: React.ReactElement;
  testId?: string;
};

export const RecordLabelWithIcon = (props: React.PropsWithChildren<Props>) => {
  const { icon, label, noIndent, className, testId } = props;
  return (
    <div
      className={classNames(styles.label, className, {
        [styles.noIndent]: noIndent,
      })}
      data-testid={testId}
    >
      <div className={styles.label__prefix}>
        <span className={styles.icon}>{icon}</span>
        {label}
      </div>
    </div>
  );
};
