import React from "react";

export const AsyncError404 = React.lazy(() => import("pages/ErrorPages/404"));

export const AsyncLoginError = React.lazy(
  () => import("pages/ErrorPages/Login")
);

export const AsyncLogin = React.lazy(() => import("pages/Auth/Login"));

export const AsyncCreateOrganization = React.lazy(
  () => import("pages/organization/CreateOrganization")
);

export const AsyncFindOrganization = React.lazy(
  () => import("pages/organization/FindOrganization")
);

export const AsyncChooseAccount = React.lazy(
  () => import("pages/ChooseAccount")
);

export const AsyncConfigure = React.lazy(
  () => import("pages/configure/Configure")
);

export const AsyncAccountAdminConfigure = React.lazy(
  () => import("pages/configure/AccountAdminConfigure")
);

export const AsyncGovern = React.lazy(() => import("pages/govern/Govern"));

export const AsyncDemoTool = React.lazy(
  () => import("pages/DemoTool")
);
