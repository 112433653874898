import classNames from "classnames";
import _startCase from "lodash/startCase";
import { useTranslation } from "react-i18next";

import GrantIcon from "assets/icons/Grant.svg?react";

import ContextMenuItem from "components/ContextMenu/ContextMenuItem";
import { OutlinedSelect } from "components/OutlinedSelect/OutlinedSelect";

import styles from "./styles.module.scss";

type Props = {
  onChange: (privileges: string[]) => void;
  privileges: any[];
  selectedPrivileges: any[];
};

export const GrantPrivilegesSelector = (props: Props) => {
  const { t } = useTranslation();
  const { onChange, privileges, selectedPrivileges } = props;
  return (
    <OutlinedSelect
      multiple
      onSelect={(items: string[]) => {
        onChange(items);
      }}
      allowUncheck
      searchOptions={{
        searchPlaceholder: t("roles_wizard.privileges.search"),
        noResultsText: t("roles_wizard.privileges.no_results"),
      }}
      renderValue={items => {
        return (
          <div
            className={classNames(styles.grant, {
              [styles.hasGrants]: items.length,
            })}
          >
            <GrantIcon />
            {items.length
              ? items.map(item => _startCase(item.toLowerCase())).join(" ")
              : t("roles_wizard.privileges.no_permissions")}
          </div>
        );
      }}
      wrapperClassName={styles.select}
      noBorder
    >
      {privileges.map(privilege => {
        const { text, value } = privilege;
        return (
          <ContextMenuItem
            key={value}
            value={value}
            checked={selectedPrivileges.includes(value)}
            checkedIconPlaceholder={true}
            text={text}
            direction="left"
          />
        );
      })}
    </OutlinedSelect>
  );
};
