import { useSuspenseQuery } from "@tanstack/react-query";

import { systemEngineEnvironment } from "services/environment/systemEngine";
import { ReactQueryKeysAccount } from "services/queryKeys";

type Schema = {
  tableSchema: string;
};

export const useSchemaNames = ({
  database,
}: {
  database: string;
}): Schema[] => {
  const { data } = useSuspenseQuery<Schema[], Error>({
    queryKey: [ReactQueryKeysAccount.schemaNames, database],
    queryFn: async () => {
      const result = await systemEngineEnvironment.execute<Schema>(
        `select table_schema from information_schema.tables group by table_schema`,
        { database }
      );

      const [response] = result;
      return response.rows;
    },
  });
  return data || [];
};
