export enum RolesWizardStep {
  name = "name",
  privileges = "privileges",
  assign = "assign",
}

export type NameStepType = {
  name: string;
  description: string;
};

export type AssignStepType = {
  grantee: string[] | null;
};

export type PrivilegesStepType = {
  privileges: PrivilegesState | null;
};

export type RolesDataType = {
  [RolesWizardStep.name]: NameStepType;
  [RolesWizardStep.privileges]: PrivilegesStepType;
  [RolesWizardStep.assign]: AssignStepType;
};

export type PrivilegesState = {
  engine: {
    toAssign: string[];
    toRevoke: string[];
    objects: string[];
    expanded?: boolean;
  }[];
  account: {
    toAssign: string[];
    toRevoke: string[];
    expanded?: boolean;
    objects: string[];
  }[];
  database: {
    toAssign: string[];
    toRevoke: string[];
    objects: string[];
    expanded?: boolean;
  }[];
  table: {
    toAssign: string[];
    toRevoke: string[];
    expanded?: boolean;
    objects: { catalogName: string; name: string }[];
    missingPrivilegesErrors: { databaseName?: string; schemaName?: string }[];
  }[];
  view: {
    toAssign: string[];
    toRevoke: string[];
    expanded?: boolean;
    objects: { catalogName: string; name: string }[];
    missingPrivilegesErrors: { databaseName?: string; schemaName?: string }[];
  }[];
  schema: {
    toAssign: string[];
    toRevoke: string[];
    expanded?: boolean;
    objects: { catalogName: string; name: string }[];
    missingPrivilegesErrors: { databaseName?: string }[];
  }[];
};
