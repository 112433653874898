import z from "zod";

import { AuthType } from "services/fileObjects/getFilesByPath";

import { csvSchema } from "../FormatData/schema";
import {
  ExistingTableMapData,
  NULL_TYPE,
} from "../MapData/ExistingTable/types";
import { WizardMapData } from "../MapData/Table/types";
import {
  FormatDataStep,
  IngestionStep,
  MapDataStep,
  WizardDataType,
} from "../types";

export const generateColumnsSql = (mapData: MapDataStep | undefined) => {
  const { data } = mapData ?? {};
  if (!data?.length) return "";
  return data
    .filter(({ included }) => included)
    .reduce((acc, { name, overrideName, type }, idx, arr) => {
      const columnName = overrideName || name;
      if (idx === arr.length - 1) {
        return `${acc}"${columnName}" ${type}\n)`;
      }
      return `${acc}"${columnName}" ${type},\n`;
    }, "(\n");
};

const getArgumentFromName = (
  previewData: { meta: { name: string }[] },
  name: string
) => {
  const { meta } = previewData;
  const index = meta.findIndex(column => column.name === name);
  if (index >= 0) {
    return `$${index + 1}`;
  }
  return `"${name}"`;
};

export const generateColumnsSqlForCopy = (wizardData: WizardDataType) => {
  const mapData = wizardData[IngestionStep.mapData];
  const destination = wizardData[IngestionStep.selectDestination];
  const formatData = wizardData[IngestionStep.formatData];

  const { data } = mapData ?? {};
  if (!data?.length || !formatData) return "";
  const { format, previewData, extension } = formatData;
  const { autoDetectHeader } = format as z.infer<
    typeof csvSchema
  >["formatSettings"];
  if (destination?.table) {
    return (data as ExistingTableMapData[])
      .filter(({ source_name }) => source_name !== NULL_TYPE)
      .reduce((acc, { name, source_name }, idx, arr) => {
        const destinationName = name;
        const sourceName = `"${source_name}"`;
        if (idx === arr.length - 1) {
          return `${acc}"${destinationName}" ${sourceName}\n)`;
        }
        return `${acc}"${destinationName}" ${sourceName},\n`;
      }, "(\n");
  }
  return (data as WizardMapData[])
    .filter(({ additionalColumn, included }) => !additionalColumn && included)
    .reduce((acc, { name, overrideName }, idx, arr) => {
      const destinationName = overrideName || name;
      const sourceName =
        autoDetectHeader || extension === "parquet"
          ? `"${name}"`
          : getArgumentFromName(previewData, name);
      if (idx === arr.length - 1) {
        return `${acc}"${destinationName}" ${sourceName}\n)`;
      }
      return `${acc}"${destinationName}" ${sourceName},\n`;
    }, "(\n");
};

export const generatePrimaryIndexesSql = (mapData: MapDataStep | undefined) => {
  const { data, primaryIndexes = [] } = mapData ?? {};
  if (!data?.length) return "";

  const selectedInexes = primaryIndexes.filter(index => {
    const column = data[index];
    return column.included;
  });

  if (!selectedInexes.length) return "";

  return selectedInexes.reduce((acc, index, idx, arr) => {
    const column = data[index];
    const name = column.overrideName || column.name;
    if (idx === arr.length - 1) {
      return `${acc}"${name}"`;
    }
    return `${acc}"${name}",`;
  }, "\nPRIMARY INDEX ");
};

export const generateCredentialsSql = ({
  auth,
  credential = "CREDENTIALS",
}: {
  auth: {
    authType: AuthType;
    key?: string;
    secret?: string;
    iamRole?: string;
  };
  credential?: string;
}) => {
  if (!auth) return "";

  if (auth.authType === AuthType.SECRET && auth.key && auth.secret) {
    return `\n${credential} = ( AWS_KEY_ID = '${auth.key}' AWS_SECRET_KEY = '${auth.secret}')`;
  }

  if (auth.authType === AuthType.IAM && auth.iamRole) {
    return `\n${credential} = ( AWS_ROLE_ARN = '${auth.iamRole}')`;
  }

  return "";
};

export const generateErrorSql = (formatData: FormatDataStep | undefined) => {
  if (
    !formatData ||
    !formatData.error ||
    !formatData.format ||
    formatData.useDefaultErrorSettings
  )
    return "";
  const { errorFile, maxErrorsPerFile } = formatData.error;
  let sql = "";

  if (errorFile) {
    sql += `ERROR_FILE = '${errorFile}'`;
    const { authType, key, secret, iamRole } = formatData.error;
    sql += generateCredentialsSql({
      auth: {
        authType,
        key,
        secret,
        iamRole,
      },
      credential: "ERROR_FILE_CREDENTIALS",
    });
  }

  // todo: not supported by BE
  // if (Number.isInteger(parseInt(maxErrors, 10))) {
  //   sql += `\nMAX_ERRORS = ${maxErrors}`;
  // }

  if (Number.isInteger(maxErrorsPerFile)) {
    sql += `\nMAX_ERRORS_PER_FILE = ${maxErrorsPerFile}`;
  }

  // todo: not supported by BE
  // if (onErrorAction) {
  //   sql += `\nON_ERROR = ${onErrorAction.toUpperCase()}`;
  // }

  // todo: not supported by BE
  // sql += `\nALLOW_COLUMN_MISMATCH = ${formatData.format.allowColumnMismatch
  //   .toString()
  //   .toUpperCase()}`;

  return sql;
};

export const generateCSVOptionsSql = ({
  formatData,
}: {
  formatData: FormatDataStep | undefined;
}) => {
  if (!formatData || !formatData.format) return "";
  const { extension } = formatData;
  const {
    newlineCharacter,
    fieldDelimiter,
    nullCharacter,
    escapeCharacter,
    skipBlankLines,
    autoDetectHeader,
    quoteCharacter,
  } = formatData.format as z.infer<typeof csvSchema>["formatSettings"];

  return `
TYPE = ${extension}
NEWLINE = e'${newlineCharacter}'
DELIMITER = '${fieldDelimiter}'
QUOTE = ${quoteCharacter}
NULL_STRING = '${nullCharacter}'
ESCAPE = '${escapeCharacter}'
SKIP_BLANK_LINES = ${skipBlankLines.toString().toUpperCase()}
HEADER = ${autoDetectHeader.toString().toUpperCase()}`;
};
