import classNames from "classnames";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useMenu } from "components/ActionMenu/useMenu";
import { Menu } from "components/LeftSidebar/RecordMenu/RecordMenu";
import { Search } from "components/LeftSidebar/Search";

import { ROLES_WIZARD_TOGGLE_EXPAND_ROWS } from "../privilegesReducer";
import { usePrivilegesState } from "../usePrivilegesContext";
import { AccountGroup } from "./AccountGroup";
import { DatabasesGroup } from "./DatabasesGroup";
import { EnginesGroup } from "./EnginesGroup";
import { SchemasGroup } from "./SchemasGroup";
import { TablesGroup } from "./TablesGroup";
import { ViewsGroup } from "./ViewsGroup";

import styles from "./styles.module.scss";

const TableHeader = () => {
  const { privilegesState, privilegesDispatch } = usePrivilegesState();
  const { t } = useTranslation();
  const menu = useMenu();

  const anyExpanded = useMemo(
    () =>
      Object.keys(privilegesState).reduce((acc, resource) => {
        const resourceGroups =
          privilegesState[resource as keyof typeof privilegesState];
        for (const group of resourceGroups) {
          if (group.expanded) {
            // eslint-disable-next-line no-param-reassign
            acc = true;
          }
        }
        return acc;
      }, false),
    [privilegesState]
  );
  const items = [
    [
      {
        key: "expand_rows",
        text: anyExpanded
          ? t("roles_wizard.privileges.menu.collapse")
          : t("roles_wizard.privileges.menu.expand"),
        action: () => {
          privilegesDispatch({
            type: ROLES_WIZARD_TOGGLE_EXPAND_ROWS,
          });
        },
      },
    ],
  ];
  return (
    <div className={styles.row}>
      <div className={classNames(styles.column, styles.column__objects)}>
        {t("roles_wizard.privileges.column_objects")}
      </div>
      <div className={classNames(styles.column, styles.column__granted)}>
        {t("roles_wizard.privileges.column_granted")}
      </div>
      <div className={classNames(styles.column, styles.column__revoked)}>
        {t("roles_wizard.privileges.column_revoked")}
      </div>
      <div className={styles.expandMenu}>
        <Menu
          items={items}
          menu={menu}
          alwaysVisible
        />
      </div>
    </div>
  );
};

export const PrivilegesTable = () => {
  const { t } = useTranslation();
  const sections = [
    {
      type: "account",
      title: t("roles_wizard.privileges.column_accounts"),
      Component: AccountGroup,
    },
    {
      type: "database",
      title: t("roles_wizard.privileges.column_databases"),
      Component: DatabasesGroup,
    },
    {
      type: "engine",
      title: t("roles_wizard.privileges.column_engines"),
      Component: EnginesGroup,
    },
    {
      type: "table",
      title: t("roles_wizard.privileges.column_tables"),
      Component: TablesGroup,
    },
    {
      type: "schema",
      title: t("roles_wizard.privileges.column_schemas"),
      Component: SchemasGroup,
    },
    {
      type: "view",
      title: t("roles_wizard.privileges.column_views"),
      Component: ViewsGroup,
    },
  ];

  const [search, setSearch] = useState("");

  const handleChangeSearch = (value: string) => {
    setSearch(value);
  };

  const { privilegesState } = usePrivilegesState();

  const filteredSections = sections.filter(({ title }) =>
    title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  return (
    <div className={styles.table}>
      <div className={styles.table__inner}>
        <div className={styles.table__header}>
          <TableHeader />
          <Search
            value={search}
            onChange={handleChangeSearch}
            placeholder="Search"
            testId="search-roles"
            className={styles.search}
          />
        </div>
        {filteredSections.map(section => {
          const { Component, title, type } = section;
          const sectionGroups =
            privilegesState[type as keyof typeof privilegesState];
          return (
            <div
              className={styles.resource}
              key={title}
            >
              <div className={styles.resource__header}>{title}</div>
              <div className={styles.sectionGroup}>
                {sectionGroups.map((_group, index) => {
                  return (
                    <Component
                      // eslint-disable-next-line react/no-array-index-key -- it's fine here
                      key={index}
                      index={index}
                      resource={type}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
